
export const alertMessage = {
  error: {
    network: 'خطا در برقراری اتصال لطفا مجدد امتحان کنید',
    Required: 'فلید الزامی است',
    MaxCharacters: 'بیش از ۲۰ کاراکتر مجاز نمی باشد',
    typePhone: 'پیش شماره تلفن اشتباه است',
    capacity: 'موجودی محصول کافی نمی باشد', 
    nullAddressBasket: 'یک ادرس انتخاب کنید',
    address: 'ادرس بیش از 200 کاراکتر مجاز نمی باشد',
    post_code: 'کد پستی نامعتبر',
    error_count: 'مقدار وارد شده صحیح نمی باشد',
    min_password: 'رمز عبور نباید کمتر از 6 کاراکتر باشد',
    error_password: 'رمز عبور اشتباه است',
    registerd: 'شما در گذشته ثبت نام کرده اید',
    old_confirm: 'حساب شما در گذشته تایید شده است',
    not_confirm: 'حساب شما تایید نشده است',
    error_pass_user: 'شماره موبایل و یا رمز عبور شما اشتباه است',
    type_number: 'تنها اعداد مجاز هستند',
    way_pay: 'لطفا یک شیوه پرداخت انتخاب کنید!',
    null_Time_basket: 'لطفا زمان دریافت را مشخص کنید',
    error_submit_basket: 'سبد خرید به روز شده است. لطفا مراحل سبد خرید را مجددا طی بفرمایید',
    error_add_basket: 'اشکال در افزودن کالا به سبد خرید',
    basket_update: 'ابتدا خطاهای اقلام سبد خرید را برطرف نمایید',
    no_login: 'لطفا ابتدا وارد سایت شوید',
    wrong_zone: 'ناحیه انتخاب شده صحیح نمی باشد',
    accept_rules: 'تایید قوانین الزامی می باشد.',
    duplicate_nick_name: "این نام کاربری استفاده شده است !!"
  },
  success: {
    update: 'باموفقیت ویرایش شد',
    send_code: 'رمز عبور به شماره شما ارسال شد',
    comment: 'نظر شما ثبت شد',
    confirm: 'احراز هویت شما با موفقیت ثبت شد',
    registerd: 'حساب شما با موفقیت ایجاد شد ',
    send_again: 'کد فعال سازی مجدد برای شما ارسال شد'
  }
}
